* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Nunito", sans-serif;
  background-color: #3a5ba0;
  color: white;
}

.header {
  padding: 20px;
}

.nav-list {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
}

.nav-list li {
  display: inline;
  margin: 0 15px;
}

.nav-list a {
  color: white;
  text-decoration: none;
  font-size: 16px;
  font-weight: 800;
}

.nav-link {
  font-size: 16px;
  font-weight: 800;
  margin: 0 10px;
}

.icon {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  margin: 0 10px;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  height: 40px;
  vertical-align: middle;
}

.logo-text {
  color: white;
  font-size: 18px;
  font-weight: 800;
  margin-left: 10px;
}

.spacer {
  flex-grow: 1;
}

.home {
  text-align: center;
  background-color: #3a5ba0;
  color: white;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.teddy-bear {
  max-width: 40%;
  height: auto;
}

.text-container {
  margin-left: 20px;
  text-align: center;
}

h1,
h2,
h3 {
  margin: 0;
}

h1 {
  font-size: 2.5em;
}

h2 {
  font-size: 2em;
}

h3 {
  font-size: 1.5em;
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.since {
  font-size: 0.75em;
  margin-left: 10px;
}

button {
  background-color: white;
  color: #3a5ba0;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  border-radius: 20px;
}

div {
  white-space: pre-wrap;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none; /* 밑줄 제거 */
}

.react-calendar {
  width: 100%;
  height: 300px;
}
